import dayjs from 'dayjs';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';
import { capitalize } from './Utils';
export const convertFloatToCommaSeperatedNumber = (number) => {
    const floatNumber = parseFloat(number).toFixed(2);
    return floatNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPhoneNumber = (e) => {
    if (e) {
        const phoneNumber = parsePhoneNumber(e);
        if (
            phoneNumber &&
            isValidPhoneNumber(phoneNumber?.number, phoneNumber?.country)
        ) {
            let formattedPhoneNumber = phoneNumber.formatInternational();
            return formattedPhoneNumber.replace(/\s+/g, '-');
        }
    }
};

export const formatDate = (selectedDate, dateFormat) => {
    if (!selectedDate) return null;
    // If selectedDate is a string, try to parse it to a Date object
    const date =
        typeof selectedDate === 'string'
            ? new Date(selectedDate)
            : selectedDate;

    // Check if the date is valid
    if (isNaN(date)) {
        console.error('Invalid date:', selectedDate);
        return null;
    }

    if (dateFormat) {
        console.log(dateFormat);
        return date instanceof Date ? date.getFullYear() : selectedDate;
    }

    const day = date.getDate();
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${day} ${monthNames[monthIndex]} ${year}`;
};

export const formatTime = (antdValue, is12HourFormat = true) => {
    if (!antdValue) return null;
    let dateTime = dayjs(antdValue);

    if (is12HourFormat) {
        return dateTime.format('hh:mm A'); // 12-hour format with AM/PM indicator
    } else {
        return dateTime.format('HH:mm'); // 24-hour format
    }
};

export const sortingData = (data) => {
    const sortedData = data.slice().sort((a, b) => {
        if ('date' in a && 'date' in b) {
            // Sort by the 'date' field if both objects have it
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA - dateB;
        } else {
            // Sort by the 'heading' field if either object doesn't have 'date'
            const titleA = a.heading ? a.heading.toLowerCase() : '';
            const titleB = b.heading ? b.heading.toLowerCase() : '';
            return titleA.localeCompare(titleB);
        }
    });
    return sortedData;
};

export const formatURL = (url) => {
    url = url?.trim(); // Remove leading/trailing whitespace
    url = url?.replace(/^http:\/\//i, 'https://');
    if (!url?.match(/^(https?:\/\/|www\.)/i)) {
        url = `https://www.${url}`;
    } else if (!url?.match(/^(https?:\/\/)/i)) {
        url = `https://${url}`;
    }

    return url;
};

export const formatNumber = (number, maximumFractionDigits = 2) => {
    if (number) {
        return parseFloat(number).toLocaleString('en-US', {
            maximumFractionDigits: maximumFractionDigits,
        });
    }
};
export const adjustTextAreaHeight = (textarea) => {
    if (textarea) {
        textarea.rows = 1; // Reset the rows to 1 to calculate the correct scrollHeight
        const numberOfRows = OnloadTextareaHeight(textarea);
        textarea.rows = numberOfRows; // Set the rows to the calculated number of rows
    }
};

export const OnloadTextareaHeight = (textarea) => {
    if (textarea) {
        const style = getComputedStyle(textarea);
        const lineHeight = parseFloat(style.lineHeight);
        const scrollHeight = textarea.scrollHeight;

        // Calculate the number of rows based on the scrollHeight and lineHeight
        let numberOfRows = Math.floor(scrollHeight / lineHeight);
        textarea.style.height = `${scrollHeight}px`;
        return numberOfRows;
    }
};

export const generateSlug = (string) => {
    return string
        ?.trim()
        .toLowerCase()
        .replace(/[^\w\s]/g, '')
        .replace(/\s+/g, '-');
};

export const getModifiedUrl = (string, url) => {
    const parts = url.split('/');
    const filename = parts[parts.length - 1];
    const modifiedFilename = `${string}${filename}`;
    parts[parts.length - 1] = modifiedFilename;
    return parts.join('/');
};

// Count the number of input fields in formInputs starting with "year"
export const countDynamicFields = (formInputs, inputKey) => {
    let count = 0;
    for (const key in formInputs) {
        if (key.startsWith(inputKey)) {
            count++;
        }
    }
    return count;
};

/**
 * Generates a file name based on user information, a default title, and file type.
 *
 * @param {Object} user - The user object containing user details.
 * @param {string} defaultTitle - The default title to be included in the file name.
 * @param {string} fileType - The file extension/type.
 * @returns {string} The generated file name in the format: Toolkit.law-defaultTitle-userName.fileType
 */
export const createFileName = (user, defaultTitle, fileType, title) => {
    const userName = getUserName(user);
    const titles = (
        title !== undefined && title !== null ? title : defaultTitle
    )
        .trim()
        .split(' ')
        .join('-');

    return `Toolkit.law-${titles}-${userName}.${fileType}`;
};

export const launchMap = (address) => {
    if (address) {
        window.open(
            `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                address,
            )}`,
            '_blank',
        );
    }
};

export const handlePaste = (setValue, fieldType) => {
    navigator.clipboard.readText().then((text) => {
        if (fieldType === 'date') {
            const parsedDate = new Date(text);
            if (!isNaN(parsedDate.getTime())) {
                setValue(parsedDate.toISOString().split('T')[0]);
            }
        } else {
            setValue(text);
        }
    });
};

export const handlePhoneClick = (phoneNumber) => () => {
    if (phoneNumber) {
        window.location.href = `tel:${phoneNumber}`;
    }
};

export const handleClear = (setValue) => setValue('');

export const getUserName = (user) => {
    const userName =
        user && user?.name ? capitalize(user?.name).split(' ').join('_') : '';
    return userName;
};

export const formatColorValuetoCSSColor = (colorObject) => {
    return `rgb(${colorObject.r}, ${colorObject.g}, ${colorObject.b})`;
};

export const logDebug = (...args) => {
    if (process.env.REACT_APP_DEBUG === 'true') {
        console.log(...args);
    }
};

export const logError = (...args) => {
    if (process.env.REACT_APP_DEBUG === 'true') {
        console.error(...args);
    }
};

/**
 * Custom logger that logs only in development mode.
 *
 */
class Logger {
    static isDevelopment =
        process.env.REACT_APP_DEBUG === 'true' ||
        new URLSearchParams(window.location.search).get('debug') === 'true';

    log(...args) {
        if (Logger.isDevelopment) {
            console.log(...args);
        }
    }

    error(...args) {
        if (Logger.isDevelopment) {
            console.error(...args);
        }
    }

    warn(...args) {
        if (Logger.isDevelopment) {
            console.warn(...args);
        }
    }

    info(...args) {
        if (Logger.isDevelopment) {
            console.info(...args);
        }
    }

    debug(...args) {
        if (Logger.isDevelopment) {
            console.debug(...args);
        }
    }
}
export const logger = new Logger();

/**
 * Creates a debounced function that delays invoking the provided function until
 * after the specified wait time has elapsed since the last time the debounced
 * function was invoked.
 *
 * @param {Function} func - The function to debounce.
 * @param {number} wait - The number of milliseconds to delay.
 * @returns {Function} - Returns the new debounced function.
 *
 * @example
 *
 * const debouncedLog = debounce(() => console.log('Hello!'), 300);
 * window.addEventListener('resize', debouncedLog);
 *
 * // 'Hello!' will be logged to the console 300 milliseconds after the last resize event.
 */
export function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

export const handleEmailClick = (title, pageName) => {
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');

    const section = urlParts[3];

    let emailBody = '';
    const emailSubject = `${title} Generated by Toolkit.law`;
    emailBody = `I am sharing with you the results of the ${title}  from the ${pageName} ${
        section === 'numbers' ? 'calculator' : ''
    } in the ${section} section of Toolkit.law.`;
    emailBody += '\n\n';
    emailBody += `For more information, see the ${section} section of Toolkit.law at https://www.toolkit.law/${section}.`;
    emailBody += '\n\n';
    const mailtoLink = `mailto:?subject=${encodeURIComponent(
        emailSubject,
    )}&body=${encodeURIComponent(emailBody)}`;
    window.open(mailtoLink, '_blank');
};
