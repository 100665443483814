import { useContext } from 'react';

import { Backdrop, Typography, Box, keyframes } from '@mui/material';

import { LoaderContext } from '../../../context/LoaderContext';

const waveAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const Loader = () => {
    const { showLoader, loaderMessage } = useContext(LoaderContext);

    if (!showLoader) {
        return null; // Don't render anything if showLoader is false
    }

    return (
        <Backdrop
            sx={{
                zIndex: (theme) => theme.zIndex.modal + 1,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
            open={showLoader}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 3,
                    bgcolor: 'primary.main',
                    borderRadius: 2,
                    border: '2px solid black',
                    maxWidth: '600px',
                    maxHeight: '400px',
                }}
            >
                <Typography variant="h3" sx={{ fontSize: '1.75rem', mb: 2 }}>
                    {loaderMessage}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                    {Array.from({ length: 6 }).map((_, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: '0.4em',
                                height: '0.4em',
                                backgroundColor: 'black',
                                margin: '0 0.1em',
                                borderRadius: '50%',
                                animation: `${waveAnimation} 1.2s ease-in-out infinite`,
                                animationDelay: `${index * 0.1}s`,
                            }}
                        />
                    ))}
                </Box>
            </Box>
        </Backdrop>
    );
};

export default Loader;
