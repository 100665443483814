import { lazy, useEffect, useState } from 'react';

import { Container, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet-async';

import ProfilePageSkeleton from '../../components/skeleton/profilePage/ProfilePageSkeleton';
import { logError } from '../../utils/SiteHelpers';
import publicAxios from '../../utils/api';
import Loader from '../../components/reusable/Loader/Loader';

const NotFoundPage = lazy(() => import('../NotFoundPage'));
const About = lazy(() => import('../../components/profilePage/About'));
const Services = lazy(() =>
    import('../../components/profilePage/services/Services'),
);
const Education = lazy(() => import('../../components/profilePage/Education'));
const Heading = lazy(() => import('../../components/profilePage/Heading'));
const BasicInformation = lazy(() =>
    import('../../components/profilePage/basicInformation/BasicInformation'),
);
const ToolkitLinks = lazy(() =>
    import('../../components/profilePage/ToolkitLinks'),
);
const CopyrightText = lazy(() =>
    import('../../components/includes/footer/CopyrightText'),
);

const ProfilePage = () => {
    const { user_slug } = useParams();
    const { user, isLoading } = useAuth0();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const getUserData = async () => {
            try {
                const response = await publicAxios.post('users/user-profile', {
                    user_slug,
                });

                if (response.status !== 200) {
                    throw new Error(
                        `Unexpected response status: ${response.status}`,
                    );
                }

                setUserData(response.data.data);
            } catch (error) {
                setError(true);
                logError(`Error fetching user data: ${error}`);
            } finally {
                setLoading(false);
            }
        };

        if (!isLoading) {
            getUserData();
        }
    }, [user, user_slug, isLoading]);

    if (isLoading || loading) {
        return <ProfilePageSkeleton />;
    }

    if (
        error ||
        !userData ||
        userData?.custom_component_data?.['profile-is-visible']
    ) {
        return <NotFoundPage />;
    }

    const getSeoTitle = () => {
        const name =
            userData?.given_name && userData?.family_name
                ? `${userData.given_name} ${userData.family_name}`
                : userData?.name
                ? userData.name
                : 'User';

        return `${name} | Toolkit.law`;
    };

    const renderAboutSection = () => {
        if (userData?.user_setting?.about_notepad) {
            return (
                <Grid item xs={12}>
                    <About data={userData.user_setting.about_notepad} />
                </Grid>
            );
        }
        return null;
    };

    const renderServicesSection = () => {
        if (userData && userData.length !== 0) {
            return (
                <Grid item xs={12}>
                    <Services data={userData} />
                </Grid>
            );
        }
        return null;
    };

    const renderEducationSection = () => {
        const data = userData?.education;
        if (data && data.length !== 0) {
            return (
                <Grid item xs={12}>
                    <Education data={data} />
                </Grid>
            );
        }
        return null;
    };

    const renderCopyrightSection = () => {
        if (userData?.public_settings?.copy_right) {
            return (
                <Grid item xs={12}>
                    <CopyrightText
                        copyright={userData.public_settings.copy_right}
                        alignmentSetting={1}
                    />
                </Grid>
            );
        }
        return null;
    };

    return (
        <>
            <Helmet>
                <title>{getSeoTitle()}</title>
                <meta
                    name="description"
                    content={userData.user_setting?.about_notepad || ''}
                />
                <meta
                    name="keywords"
                    content="toolkit.law, legal services, mediation services, arbitration services"
                />
            </Helmet>
            <Container maxWidth="lg" sx={{ py: 3 }}>
                <Loader />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Heading userData={userData} />
                    </Grid>
                    {renderAboutSection()}
                    {renderServicesSection()}
                    {renderEducationSection()}
                    <Grid item sm={6} xs={12}>
                        <BasicInformation userData={userData} />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <ToolkitLinks />
                    </Grid>
                    {renderCopyrightSection()}
                </Grid>
            </Container>
        </>
    );
};

export default ProfilePage;
