import { createContext, useContext, useReducer, useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { getAccessTokenAndUserId } from '../utils/auth';
import { userAxios } from '../utils/api';
import { logger } from '../utils/SiteHelpers';
import { AlertMessageContext } from './AlertMessageContext';
import { LoaderContext } from './LoaderContext';

export const CartContext = createContext();

const initialState = {
    cart: {},
    loading: false,
    error: null,
};

const ACTIONS = {
    SET_LOADING: 'SET_LOADING',
    SET_ERROR: 'SET_ERROR',
    SET_CART: 'SET_CART',
    ADD_TO_CART: 'ADD_TO_CART',
    REMOVE_FROM_CART: 'REMOVE_FROM_CART',
    UPDATE_CART: 'UPDATE_CART',
    PROCESS_PAYMENT: 'PROCESS_PAYMENT',
    CLEAR_ERROR: 'CLEAR_ERROR',
};

const cartReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_LOADING:
            return { ...state, loading: action.payload };
        case ACTIONS.SET_ERROR:
            return { ...state, error: action.payload };
        case ACTIONS.SET_CART:
            return { ...state, cart: action.payload };
        case ACTIONS.ADD_TO_CART:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    vendors: (state.cart.vendors || []).map((vendor) =>
                        vendor.vendor.id === action.payload.vendor_id
                            ? {
                                  ...vendor,
                                  items: [...vendor.items, action.payload.item],
                              }
                            : vendor,
                    ),
                },
            };
        case ACTIONS.REMOVE_FROM_CART:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    vendors: state.cart.vendors.map((vendor) => ({
                        ...vendor,
                        items: vendor.items.filter(
                            (item) => item.item_id !== action.payload,
                        ),
                    })),
                },
            };
        case ACTIONS.UPDATE_CART:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    vendors: state.cart.vendors.map((vendor) => ({
                        ...vendor,
                        items: vendor.items.map((item) =>
                            item.item_id === action.payload.item_id
                                ? { ...item, quantity: action.payload.quantity }
                                : item,
                        ),
                    })),
                },
            };
        case ACTIONS.CLEAR_ERROR:
            return { ...state, error: null };
        default:
            return state;
    }
};

export const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, initialState);
    const { cart, loading, error } = state;
    const { user } = useAuth0();
    const { setSuccessMessage, setErrorMessage } =
        useContext(AlertMessageContext);
    const { setShowLoader, setLoaderMessage } = useContext(LoaderContext);

    const getCart = useCallback(async () => {
        dispatch({ type: ACTIONS.SET_LOADING, payload: true });
        dispatch({ type: ACTIONS.CLEAR_ERROR });

        try {
            const { accessToken, userId } = await getAccessTokenAndUserId(user);
            const payload = {
                user_id: userId,
            };
            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            const response = await userAxios.post(`cart/get`, payload, config);

            logger.log('Fetch Cart Response:', response.data);

            dispatch({ type: ACTIONS.SET_CART, payload: response.data });
        } catch (error) {
            logger.error('Fetch Cart Error:', error);
            dispatch({
                type: ACTIONS.SET_ERROR,
                payload: 'Failed to load cart. Please try again later.',
            });
        } finally {
            dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        }
    }, [user]);

    const addToCart = useCallback(
        async (item) => {
            setShowLoader(true);
            setLoaderMessage('Adding Item to Cart...');

            try {
                const { accessToken, userId } = await getAccessTokenAndUserId(
                    user,
                );
                const payload = {
                    ...item,
                    user_id: userId,
                };
                const config = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };

                const response = await userAxios.post(
                    `cart/add`,
                    payload,
                    config,
                );
                logger.log('Add to Cart Response:', response.data);

                if (response.data.message) {
                    setSuccessMessage('Item added to cart successfully!');
                }

                dispatch({
                    type: ACTIONS.ADD_TO_CART,
                    payload: {
                        vendor_id: item.vendor_id,
                        item: item,
                    },
                });
            } catch (error) {
                logger.error('Add to Cart Error:', error);
                const errorMessage =
                    'Failed to add item to cart. Please try again later.';
                setErrorMessage(errorMessage);
                dispatch({
                    type: ACTIONS.SET_ERROR,
                    payload: errorMessage,
                });
            } finally {
                setShowLoader(false);
            }
        },
        [user],
    );

    const removeFromCart = useCallback(
        async (item_id) => {
            setShowLoader(true);
            setLoaderMessage('Removing Item from Cart...');

            try {
                const { accessToken, userId } = await getAccessTokenAndUserId(
                    user,
                );
                const payload = {
                    item_id,
                    user_id: userId,
                };
                const config = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };

                const response = await userAxios.post(
                    `cart/remove`,
                    payload,
                    config,
                );

                logger.log('Remove from Cart Response:', response.data);

                dispatch({ type: ACTIONS.REMOVE_FROM_CART, payload: item_id });
            } catch (error) {
                logger.error('Remove from Cart Error:', error);
                const errorMessage =
                    'Failed to remove item from cart. Please try again later.';
                setErrorMessage(errorMessage);
            } finally {
                setShowLoader(false);
            }
        },
        [user],
    );

    const updateCart = useCallback(
        async (item_id, quantity) => {
            setShowLoader(true);
            setLoaderMessage('Updating Cart...');

            try {
                const { accessToken, userId } = await getAccessTokenAndUserId(
                    user,
                );
                const payload = {
                    user_id: userId,
                    item_id,
                    quantity,
                };
                const config = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };

                const response = await userAxios.post(
                    'cart/update',
                    payload,
                    config,
                );
                logger.log('Update Cart Response:', response.data);

                dispatch({
                    type: ACTIONS.UPDATE_CART,
                    payload: { item_id, quantity },
                });
            } catch (error) {
                logger.error('Update Cart Error:', error);
                const errorMessage =
                    'Failed to update cart. Please try again later.';
                setErrorMessage(errorMessage);
            } finally {
                setShowLoader(false);
            }
        },
        [user],
    );

    const processPayment = useCallback(
        async (cart_id) => {
            setShowLoader(true);
            setLoaderMessage('Processing Payment...');

            try {
                const { accessToken, userId } = await getAccessTokenAndUserId(
                    user,
                );
                const payload = {
                    user_id: Number(userId),
                    cart_id,
                };
                const config = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };

                const response = await userAxios.post(
                    'cart/checkout',
                    payload,
                    config,
                );

                logger.log('Payment Response:', response.data);

                if (response.data.error) {
                    setErrorMessage(response.data.message);
                    return;
                }

                window.open(response.data.redirectUrl, '_self');

                dispatch({
                    type: ACTIONS.PROCESS_PAYMENT,
                    payload: response.data,
                });
            } catch (error) {
                logger.error('Payment Error:', error);

                const errorMessage = 'Failed to process payment.';

                setErrorMessage(errorMessage);
            } finally {
                setShowLoader(false);
            }
        },
        [user],
    );

    // TODO: Confirm item quantity and manage state
    const cartItemCount = (cart.vendors || []).reduce(
        (count, vendor) =>
            count +
            vendor.items.reduce(
                (vendorCount, item) => vendorCount + item.quantity,
                0,
            ),
        0,
    );

    return (
        <CartContext.Provider
            value={{
                cart,
                loading,
                error,
                getCart,
                addToCart,
                removeFromCart,
                updateCart,
                cartItemCount,
                processPayment,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
