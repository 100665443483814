// src/components/ProtectedRoute.js
import { useAuth0 } from '@auth0/auth0-react';

const LoginProtected = ({ element, ...rest }) => {
    const { isAuthenticated, user, loginWithRedirect } = useAuth0();

    if (!isAuthenticated || !user) {
        loginWithRedirect();
        return;
    }

    return element;
};

export default LoginProtected;
