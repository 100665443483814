import { lazy, Suspense, useState } from 'react';

import { Routes, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Box } from '@mui/material';

import SplashScreenWrapper from './components/reusable/splashScreen/SplashScreenWrapper';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/profile/ProfilePage';
import NotFound from './pages/NotFoundPage';
import { CartProvider } from './context/CartContext';
import { AlertMessageContext } from './context/AlertMessageContext';
import { LoaderProvider } from './context/LoaderContext';
import { AccountModalProvider } from './context/AccountModalContext';
import { UpgradeModalProvider } from './context/UpgradeModalContext';
import { UserSettingsProvider } from './context/UserSettingsContext';
import { getManifestUrl } from './utils/Utils';
import { ThemeProvider } from '@mui/material/styles';
import theme from './css/muiStyles/theme';
import './App.css';

const AlertMessages = lazy(() => import('./components/reusable/AlertMessages'));

const App = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const manifestUrl = getManifestUrl();

    return (
        <HelmetProvider>
            <LoaderProvider>
                <AlertMessageContext.Provider
                    value={{
                        successMessage,
                        errorMessage,
                        setSuccessMessage,
                        setErrorMessage,
                    }}
                >
                    <AccountModalProvider>
                        <UpgradeModalProvider>
                            <ThemeProvider theme={theme}>
                                <CartProvider>
                                    <Box>
                                        <Helmet>
                                            <link
                                                rel="manifest"
                                                href={manifestUrl}
                                            />
                                            <meta
                                                name="theme-color"
                                                content="#ffffff"
                                            />
                                            <meta
                                                name="theme-color"
                                                content="#ffffff"
                                            />
                                            <meta
                                                name="msapplication-navbutton-color"
                                                content="#ffffff"
                                            />
                                            <meta
                                                name="apple-mobile-web-app-status-bar-style"
                                                content="white"
                                            />
                                            <link
                                                rel="apple-touch-icon"
                                                href="%PUBLIC_URL%/logo192.png"
                                            />
                                        </Helmet>
                                        <SplashScreenWrapper>
                                            <Routes>
                                                <Route
                                                    path="/*"
                                                    element={
                                                        <UserSettingsProvider>
                                                            <HomePage />
                                                        </UserSettingsProvider>
                                                    }
                                                />
                                                <Route
                                                    path="/user/:user_slug"
                                                    element={<ProfilePage />}
                                                />
                                                <Route
                                                    path="not-found"
                                                    element={<NotFound />}
                                                />
                                            </Routes>

                                            <Suspense
                                                fallback={<div>Loading...</div>}
                                            >
                                                <AlertMessages />
                                            </Suspense>
                                        </SplashScreenWrapper>
                                    </Box>
                                </CartProvider>
                            </ThemeProvider>
                        </UpgradeModalProvider>
                    </AccountModalProvider>
                </AlertMessageContext.Provider>
            </LoaderProvider>
        </HelmetProvider>
    );
};

export default App;
